import React, { useEffect, useState } from 'react';
// import * as Yup from "yup";
import { Field, Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useStyles } from "react-styles-hook";
import getStyles from './styles';
import { updateUserLaunched, firstShippingDeliveryLaunched } from '../../state/reducer';
import ModalEditPassword from '../../components/ModalEditPassword';

const AccountInfo = () => {
  const classes = useStyles(getStyles());
  const dispatch = useDispatch();

  const [send, setSend] = useState(false);

  const { userDynamo, updateUserLoading, updateUserSuccess, updateUserFailure, naturalUserMangopayLoading } = useSelector((state) => ({
    userDynamo: state.getIn(['root', 'userDynamo']),
    updateUserLoading: state.getIn(['root', 'updateUserLoading']),
    updateUserSuccess: state.getIn(['root', 'updateUserSuccess']),
    updateUserFailure: state.getIn(['root', 'updateUserFailure']),
    naturalUserMangopayLoading: state.getIn(['root', 'naturalUserMangopayLoading'])
  }));

  const [currentUser, setCurrentUser] = useState(userDynamo);

  useEffect(() => {
    if (userDynamo !== null) {
      setCurrentUser(userDynamo);
    }
  }, [userDynamo]);

  const customerInfo = currentUser?.user;
  const customerDeliveryInfo = currentUser?.user.deliveryInformations;
  const userId = customerInfo?.id;

  // Modale
  const [openModalEditPassword, setOpenModalEditPassword] = useState(false);
  let body;
  if (typeof window !== "undefined") { // during build window is unavailable
    body = document.querySelector("body");
  }
  const showModal = () => {
    setOpenModalEditPassword(true);
    if (typeof window !== "undefined") {
      body.style.overflow = "hidden"; // block scroll on body when modal is open
    }
  };
  const hideModal = () => {
    setOpenModalEditPassword(false);
    if (typeof window !== "undefined") {
      body.style.overflow = "auto";
    }
  };

  // Form user infos
  let initialValues;
  if (customerInfo && customerDeliveryInfo) {
    initialValues = {
      id: userId,
      firstName: customerInfo.firstName,
      lastName: customerInfo.lastName,
      address: customerDeliveryInfo?.address,
      zipCode: customerDeliveryInfo?.zipCode,
      city: customerDeliveryInfo?.city,
      country: customerDeliveryInfo?.country,
      phone: customerInfo.phone
    };
  } else {
    initialValues = {
      id: "",
      firstName: "",
      lastName: "",
      address: "",
      zipCode: "",
      city: "",
      country: "",
      phone: ""
    };
  }

  const [formValue, setFormValue] = useState({
    id: initialValues.id,
    firstName: initialValues.firstName,
    lastName: initialValues.lastName,
    address: initialValues.address,
    zipCode: initialValues.zipCode,
    city: initialValues.city,
    country: initialValues.country,
    phone: initialValues.phone || ''
  });

  const [phone, setPhone] = useState(formValue.phone || '');

  let dataReconfigured = {};

  // Form submitting function to save
  const onSubmit = () => { // ! Sorry, but React doesnt allow to use json in formValues
    dataReconfigured = { // * dataReconfigured = data that will be send to sagas.
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      deliveryInformations: {
        address: formValue.address,
        zipCode: formValue.zipCode,
        city: formValue.city,
        country: formValue.country
      },
      phone: formValue.phone
    };
    // if delivery info is undefined : generate mangopayId & walletId at first time 
    if (!userDynamo.user.mangopayId && !userDynamo.user.deliveryInformations) {
      dispatch(firstShippingDeliveryLaunched(dataReconfigured));
    } else {
      dispatch(updateUserLaunched(dataReconfigured));
    };
    setSend(true);
  };

  return (
    <>
      <div className="space-y-8 divide-y w-full max-w-4xl">
        <div className="space-y-8 divide-y sm:space-y-5 p-6">
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors = {};

              if (!values.firstName) {
                errors.firstName = "Prénom obligatoire";
              }

              if (!values.lastName) {
                errors.lastName = "Nom obligatoire";
              }

              if (!values.address) {
                errors.address = "Adresse obligatoire";
              }

              if (!values.city) {
                errors.city = "Ville obligatoire";
              }

              if (!values.zipCode) {
                errors.zipCode = "Code postal obligatoire";
              }

              if (!values.country) {
                errors.country = "Pays obligatoire";
              }

              // For validate company's Phone number
              if (isValidPhoneNumber(phone) === false) {
                errors.phone = "Téléphone invalide";
              }

              return errors;
            }}
            onSubmit={(values) => { onSubmit(values) }}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form className="space-y-6 sm:pt-10 sm:space-y-5">
                <div className='my-5'>
                  <h3 className="text-lg leading-6 font-medium text-gray-900 mb-10"> Mes <strong className='text-orange-dark'> informations </strong></h3>
                </div>
                <div>
                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <div className="w-full md:flex justify-between">
                      <label className="pb-2 block text-s text-grey font-medium">E-mail</label>
                      <div className='flex-col md:w-3/5'>
                        <div className="md:w-3/5 w-full text-left text-grey px-3 py-2 border-grey-light border rounded focus:outline-none focus:ring-orange-light focus:border-orange-dark truncate">
                          {customerInfo?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <div className="w-full md:flex justify-between">
                      <label className="pb-2 block text-s text-grey font-medium">Mot de passe</label>
                      <div className='flex-col md:w-3/5'>
                        <button
                          type="button"
                          className="md:w-3/5 w-full text-left px-3 py-2 border-grey-light border rounded focus:outline-none focus:ring-orange-light focus:border-orange-dark"
                          onClick={showModal}
                        >
                          ***********
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
                    <div className="w-full md:flex justify-between">
                      <span className="pb-2 block text-s text-grey font-medium"></span>
                      <div className='flex-col md:w-3/5'>
                        <button
                          id="editPassword"
                          name="editPassword"
                          type="button"
                          style={classes.roundedBorder}
                          className="text-white bg-orange-dark hover:bg-orange-chocolate border hover:border-0 py-2 px-4 focus:outline-none focus:ring-0"
                          onClick={showModal}
                        >
                          Modification mot de passe
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='mt-10 mb-8'>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-10"> Mon <strong className='text-orange-dark'> profil </strong></h3>
                  </div>
                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <div className="w-full md:flex justify-between">
                      <label className="pb-2 block text-s text-grey font-medium">Nom</label>
                      <div className='flex-col md:w-3/5'>
                        <Field
                          id="lastName"
                          name="lastName"
                          type="text"
                          aria-label="nom"
                          value={values.lastName}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }));
                          }}
                          style={classes.roundedBorder}
                          className={`md:w-3/5 w-full ${errors.lastName ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                        />
                        {errors.lastName && (
                          <p className="px-2 pt-2 text-center md:text-left text-orange-chocolate">
                            {errors.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <div className="w-full md:flex justify-between">
                      <label className="pb-2 block text-s text-grey font-medium">Prénom</label>
                      <div className='flex-col md:w-3/5'>
                        <Field
                          id="firstName"
                          name="firstName"
                          type="text"
                          aria-label="prénom"
                          value={values.firstName}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }));
                          }}
                          style={classes.roundedBorder}
                          className={`md:w-3/5 w-full ${errors.firstName ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                        />
                        {errors.firstName && (
                          <p className="px-2 pt-2 text-center md:text-left text-orange-chocolate">
                            {errors.firstName}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <div className="w-full md:flex justify-between">
                      <label className="pb-2 block text-s text-grey font-medium">Adresse</label>
                      <div className='flex-col md:w-3/5'>
                        <Field
                          id="address"
                          name="address"
                          type="text"
                          aria-label="adresse"
                          value={values.address}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }));
                          }}
                          style={classes.roundedBorder}
                          className={`md:w-3/5 w-full ${errors.address ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                        />
                        {errors.address && (
                          <p className="px-2 pt-2 text-center md:text-left text-orange-chocolate">
                            {errors.address}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <div className="w-full md:flex justify-between">
                      <label className="pb-2 block text-s text-grey font-medium">Code Postal</label>
                      <div className='flex-col md:w-3/5'>
                        <Field
                          id="zipCode"
                          name="zipCode"
                          type="text"
                          aria-label="code postal"
                          value={values.zipCode}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }));
                          }}
                          style={classes.roundedBorder}
                          className={`md:w-3/5 w-full ${errors.zipCode ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                        />
                        {errors.zipCode && (
                          <p
                            className="px-2 pt-2 text-center md:text-left text-orange-chocolate"
                          >
                            {errors.zipCode}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <div className="w-full md:flex justify-between">
                      <label className="pb-2 block text-s text-grey font-medium">Ville</label>
                      <div className='flex-col md:w-3/5'>
                        <Field
                          id="city"
                          name="city"
                          type="text"
                          aria-label="ville"
                          value={values.city}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }));
                          }}
                          style={classes.roundedBorder}
                          className={`md:w-3/5 w-full ${errors.city ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                        />
                        {errors.city && (
                          <p
                            className="px-2 pt-2 text-center md:text-left text-orange-chocolate"
                          >
                            {errors.city}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <div className="w-full md:flex justify-between">
                      <label className="pb-2 block text-s text-grey font-medium">Pays</label>
                      <div className='flex-col md:w-3/5'>
                        <Field
                          id="country"
                          name="country"
                          type="text"
                          aria-label="pays"
                          value={values.country}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }));
                          }}
                          style={classes.roundedBorder}
                          className={`md:w-3/5 w-full ${errors.country ? 'border-red focus:border-red' : 'border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark'}`}
                        />
                        {errors.country && (
                          <p
                            className="px-2 pt-2 text-center md:text-left text-orange-chocolate"
                          >
                            {errors.country}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <div className="w-full md:flex justify-between">
                      <label className="pb-2 block text-s text-grey font-medium">Numéro de téléphone</label>
                      <div className='flex-col md:w-3/5'>
                        {/* PhoneInput field : go to global css to customize it */}
                        <PhoneInput
                          international
                          defaultCountry="FR"
                          value={phone}
                          placeholder="Numéro de téléphone"
                          className="md:w-3/5 w-full border-grey-light focus:outline-none focus:ring-orange-light focus:border-orange-dark"
                          onChange={(e) => {
                            if (e === undefined) {
                              e = '' // Permits to remove former error when indicator was deleted
                            }
                            handleChange(e)
                            setPhone(e)
                            setFormValue((prevState) => ({
                              ...prevState,
                              ["phone"]: e
                            }));
                          }}

                        />
                        {isValidPhoneNumber(phone) === false && (
                          <p
                            className="px-2 pt-2 text-center md:text-left text-orange-chocolate"
                          >
                            Téléphone invalide
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {(send && updateUserSuccess) && <p className="text-center text-gray-500">Vos informations ont été mises à jour.</p>}
                {(send && updateUserFailure) && <p className="text-center text-gray-500">Une erreur est survenue, veuillez réessayer plus tard.</p>}
                <div className="pt-5 mt-5">
                  <div className="flex justify-center mt-2">
                    <button
                      type="submit"
                      className="text-white bg-orange-dark hover:bg-orange-chocolate border border-transparent rounded-lg hover:border-0 py-2 px-4 focus:outline-none focus:ring-0"
                    >
                      {(updateUserLoading || naturalUserMangopayLoading) ? 'Enregistrement...' : 'Enregistrer'}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ModalEditPassword showModal={openModalEditPassword} hideModal={hideModal} />
    </>
  );
};

export default AccountInfo
