import React, { useEffect, useState } from 'react';
import i18n from "../../../i18n";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import CustomLoader from '../../components/CustomLoader';
import CoverImage from '../../components/CoverImage';
import signUpMobile from '../../../assets/signupMobile.png';
import signUpDesktop from '../../../assets/signUpDesktop.png';
import NavBarMenu from '../../components/NavBarMenu';
import AccountInfo from '../../components/AccountInfo';
import SwitchInfoOrder from '../../components/SwitchInfoOrder';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import ModalContact from '../../components/ModalContact';
import generateMeta from '../../utils/meta';

const MyAccountPage = ({ data, pageContext }) => {
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  const lang = pageContext.locale;

  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  // check if client is connected
  const { isAuthenticated, isAuthenticating, userDynamo } = useSelector((state) => ({
    isAuthenticated: state.getIn(['root', 'isAuthenticated']),
    isAuthenticating: state.getIn(['root', 'isAuthenticating']),
    userDynamo: state.getIn(['root', 'userDynamo'])
  }));

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticating) {
      navigate(`/${lang}/login`)
    } else return;
  }, [isAuthenticating]);

  // Modale Contact
  const [openModalContact, setOpenModalContact] = useState(false);
  let body;
  if (typeof window !== "undefined") { // during build window is unavailable
    body = document.querySelector("body");
  }
  const showModal = () => {
    setOpenModalContact(true);
    if (typeof window !== "undefined") {
      body.style.overflow = "hidden"; // block scroll on body when modal is open
    }
  };

  const hideModal = () => {
    setOpenModalContact(false);
    if (typeof window !== "undefined") {
      body.style.overflow = "auto";
    }
  };

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang={pageContext.locale} />
        <title> Mon Compte </title>
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      {(!userDynamo && isAuthenticating) ? <CustomLoader /> : null}
      {isAuthenticated && (
        <>
          <CoverImage
            imageDesktop={signUpDesktop}
            imageMobile={signUpMobile}
            title="Mon Compte"
            altDesktop="Mon Compte"
            altMobile="Mon Compte"
          />
          <div className="max-w-screen-2xl mx-auto">
            <div className='lg:flex'>
              <div className='lg:w-1/4 w-full sm:w-3/4 md:w-1/2'>
                <SwitchInfoOrder lang={pageContext.locale} currentPath={pageContext.currentPath} />
              </div>
              <div className='lg:w-3/4 w-full flex justify-center'>
                <AccountInfo />
              </div>
            </div>
            {(userDynamo?.user.firstName && userDynamo?.user.lastName) && (
              <>
                <div className='text-center py-5'>
                  <p
                    className="self-center mt-6 font-normal text-xxms"
                  >
                    Vous rencontrez un problème ou avez une question ?
                  </p>
                  <button
                    type="button"
                    className="font-medium text-xxms mb-4 md:mb-8 focus:outline-none focus:ring-0"
                    onClick={showModal}
                  >
                    Contacter Zawema
                  </button>
                </div>
                <ModalContact showModal={openModalContact} hideModal={hideModal} />
              </>
            )}
          </div>
        </>
      )}
    </Layout>
  )
}

export default MyAccountPage;

export const query = graphql`
    query( $locale: String! ) {
        menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
            edges {
              node {
                slug
                categoryName
              }
            }
          }
        menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                  parentCategory {
                    slug
                    categoryName
                    categoryEncartTitle
                  }
                } 
              }
            }
        }
        markdownRemark(fileAbsolutePath: {regex: "/account.md/"}) {
          html
          frontmatter {
              title
              description
              ogtitle
              ogdescription
          }
        }
    }
`