import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordLaunched } from '../../state/reducer';
import { translateErrorChangePassword } from '../../utils/cognito'
import { useStyles } from "react-styles-hook";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { capitalizeFirst } from '../../utils/format';
import getStyles from "./styles";

export const ModalEditPassword = ({ showModal, hideModal }) => {

  const classes = useStyles(getStyles());
  const dispatch = useDispatch();

  const { loading, successStatut, errorStatut } = useSelector((state) => ({
    loading: state.getIn(['root', 'changePasswordLoading']),
    successStatut: state.getIn(['root', 'changePasswordSuccessMessage']),
    errorStatut: state.getIn(['root', 'changePasswordErrorMessage']),
  }))

  // auto close modal after 3s ONLY if password changed with success
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    }
    let timer;
    if (successStatut) {
      timer = setTimeout(() => hideModal(), 3000);
    } else {
      return null
    }

    return () => { clearTimeout(timer); };
  }, [successStatut]);

  // Set error message & success message
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  useEffect(() => {
    if (successStatut === "SUCCESS") {
      setSuccessMessage("Changement effectué avec succès")
    };
    if (errorStatut) {
      setErrorMessage(translateErrorChangePassword(errorStatut.code))
    };
  }, [successStatut, errorStatut])

  const [formValue, setFormValue] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: ""
  });

  const initialValues = {
    oldPassword: formValue.oldPassword,
    password: formValue.password,
    confirmPassword: formValue.confirmPassword,
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Veuillez saisir votre ancien mot de passe")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
        "Veuillez saisir au moins 8 caractères, une lettre, un chiffre, une majuscule et un caractère spécial"
      )
      .nullable(),
    password: Yup.string()
      .required("Veuillez saisir votre nouveau mot de passe")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
        "Veuillez saisir au moins 8 caractères, une lettre, un chiffre, une majuscule et un caractère spécial"
      )
      .nullable(),
    confirmPassword: Yup.string()
      .required("Confirmation du nouveau mot de passe requis")
      .oneOf([Yup.ref('password'), null], "Les nouveaux mots de passe ne correspondent pas")
      .nullable()
  });

  // Form submit function
  const changePassord = () => {
    dispatch(changePasswordLaunched(formValue))
  }

  return (
    showModal
    && (
      <div
        role="button"
        className="cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
        onKeyPress={hideModal}
        tabIndex="0"
      >
        <div
          aria-hidden="true"
          className="relative bg-white w-full sm:w-5/6 md:w-3/4 lg:w-1/2 2xl:w-1/3 flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8"
          // don't close modal if anything inside modal content is clicked or any key is pressed
          onClick={(e) => { e.stopPropagation(); }}
          onKeyPress={(e) => { e.stopPropagation(); }}
        >
          <div className="w-3/4 md:w-full my-2">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={changePassord}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form>
                  <div className="mt-4 flex flex-col">
                    <div className='mt-4'>
                      <label className="block text-xxms font-medium text-gray-700">
                        Veuillez saisir votre ancien mot de passe
                      </label>
                      <div className="mt-1 mb-4">
                        <Field
                          type="password"
                          id="oldPassword"
                          name="oldPassword"
                          value={values.oldPassword}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }));
                          }}
                          className="appearance-none py-3 px-4 block w-full shadow-sm rounded-md focus:ring-orange-dark focus:border-orange-dark border border-gray-300"
                        />
                        {errors.oldPassword && touched.oldPassword ? (
                          <div className="text-orange-darkest">{errors.oldPassword}</div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <label className="block text-xxms font-medium text-gray-700">
                        Veuillez saisir un nouveau mot de passe
                      </label>
                      <div className="mt-1 mb-2">
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          value={values.password}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }));
                          }}
                          className="appearance-none py-3 px-4 block w-full shadow-sm rounded-md focus:ring-orange-dark focus:border-orange-dark border border-gray-300"
                        />
                        {errors.password && touched.password ? (
                          <div className="text-orange-darkest">{errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <label className="block text-xxms font-medium text-gray-700">
                        Confirmez votre nouveau mot de passe
                      </label>
                      <div className="mt-1">
                        <Field
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value
                            }));
                          }}
                          className="appearance-none py-3 px-4 block w-full shadow-sm rounded-md focus:ring-orange-dark focus:border-orange-dark border border-gray-300"
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <div className="text-orange-darkest">{errors.confirmPassword}</div>
                        ) : null}
                      </div>
                    </div>
                    {!loading && (successMessage || errorMessage) && (
                      <div className="mt-6 flex justify-center">
                        <p>{successMessage ? successMessage : (errorMessage ? errorMessage : "Un problème est survenu")}</p>
                      </div>
                    )}
                    <div className="mt-6 flex justify-center">
                      <button
                        type="submit"
                        disabled={loading ? true : false}
                        className="w-full md:w-52 px-3 py-1.5 border border-transparent rounded-md shadow-sm text-xxms font-medium text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dark"
                      >
                        {!loading && (capitalizeFirst("Modifier"))}
                        {loading && (capitalizeFirst("Chargement"))}
                      </button>
                    </div>
                    <div className="mt-3 flex justify-center">
                      <button
                        type="button"
                        onClick={hideModal}
                        className="w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-grey bg-white hover:bg-grey-dark hover:text-white focus:outline-none focus:ring-0 focus:ring-offset-0"
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  );
};

export default ModalEditPassword;
